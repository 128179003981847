import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';

const Banks = () => {
  const navigate = useNavigate();
  const [AllBanks, setAllBanks] = useState([]);

  const getAllBanks = async () => {
    try {
      const response = await axios.get(`${StartUrl.StartUrl}/ssc/banks/getAllBanks`);
      const banks = response?.data?.data;
      if (Array.isArray(banks)) {
        setAllBanks(banks);
      } else {
        console.error("Expected an array but received:", banks);
      }
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit-banks/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${StartUrl.StartUrl}/ssc/banks/deleteBank/${id}`);
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          getAllBanks();
        } catch (error) {
          console.error("Error deleting bank:", error);
        }
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black">Bank Details</h4>
        <button
          className="bg-black text-white p-2 rounded-full"
          style={{ borderRadius: '5px' }}
          onClick={() => navigate("/add-banks")}
        >
          Add Bank
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Bank</th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Term</th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Interest</th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">kW</th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Loan Amount</th>
                  <th className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">Edit</th>
                  <th className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">Delete</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {AllBanks.map((bank) => (
                  <React.Fragment key={bank._id}>
                    {bank.rates.map((rate, index) => (
                      <tr key={index}>
                        {index === 0 && (
                          <td
                            className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
                            rowSpan={bank.rates.length}
                          >
                            {bank.bank}
                          </td>
                        )}
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{rate.term}</td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{rate.interest}</td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{rate.kW}</td>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{rate.loanAmount}</td>
                        {index === 0 && (
                          <>
                            <td
                              className="px-6 py-4 text-sm text-right whitespace-nowrap"
                              rowSpan={bank.rates.length}
                            >
                              <button
                                onClick={() => handleEdit(bank._id)}
                                className="text-green-500 hover:text-green-700"
                              >
                                Edit
                              </button>
                            </td>
                            <td
                              className="px-6 py-4 text-sm text-right whitespace-nowrap"
                              rowSpan={bank.rates.length}
                            >
                              <button
                                onClick={() => handleDelete(bank._id)}
                                className="text-red-500 hover:text-red-700"
                              >
                                Delete
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            {AllBanks.length === 0 && (
              <div className="px-6 py-4 text-sm text-gray-500 text-center">
                No banks available.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banks;
