import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

function EditSolarPackage() {
  const navigate = useNavigate();
  const { id } = useParams();  
  const [inverterType, setInverterType] = useState("");
  const [inverterCapacity, setInverterCapacity] = useState("");
  const [avgMonthlyGeneration, setAvgMonthlyGeneration] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");

  const getSolarPackageById = async () => {
    try {
      const response = await axios.get(StartUrl?.StartUrl + "/ssc/solarpackages/getOneSolarPackage/" + id);
      console.log("Solar Package data received:", response?.data?.data?.data);
      setInverterType(response?.data?.data?.data?.inverter_type);
      setInverterCapacity(response?.data?.data?.data?.inverter_capacity);
      setAvgMonthlyGeneration(response?.data?.data?.data?.avg_monthly_generation);
      setSellingPrice(response?.data?.data?.data?.selling_price);
    } catch (error) {
      console.log("Error fetching solar package data:", error);
    }
  };

  useEffect(() => {
    getSolarPackageById();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const solarPackageData = { 
      inverter_type: inverterType, 
      inverter_capacity: inverterCapacity, 
      avg_monthly_generation: avgMonthlyGeneration, 
      selling_price: sellingPrice 
    };
    console.log("Updating solar package with data:", solarPackageData);
    
    try {
      const response = await axios.put(StartUrl?.StartUrl + '/ssc/solarpackages/updateSolarPackage/' + id, solarPackageData);
      console.log("Update response:", response.data);
      if (response?.data?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Update Success!",
          text: `${response?.data?.message}`,
        });
        navigate("/solarpackages");
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed..!",
          text: `${response?.data?.message}`,
        });
      }
    } catch (error) {
      console.error("Error updating solar package:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl h-auto mx-auto bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Update Solar Package</h2>
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inverterType">
              Inverter Type
            </label>
            <input
              type="text"
              id="inverterType"
              value={inverterType}
              onChange={(e) => setInverterType(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter inverter type"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inverterCapacity">
              Inverter Capacity
            </label>
            <input
              type="text"
              id="inverterCapacity"
              value={inverterCapacity}
              onChange={(e) => setInverterCapacity(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter inverter capacity"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="avgMonthlyGeneration">
              Avg Monthly Generation
            </label>
            <input
              type="text"
              id="avgMonthlyGeneration"
              value={avgMonthlyGeneration}
              onChange={(e) => setAvgMonthlyGeneration(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter average monthly generation"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellingPrice">
              Selling Price
            </label>
            <input
              type="text"
              id="sellingPrice"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter selling price"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditSolarPackage;
