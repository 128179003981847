import React, { useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function AddExcessGeneration() {
    const navigate = useNavigate();
    const [rate, setRate] = useState(""); // Changed price to rate

    const handleSubmit = async (event) => {
        event.preventDefault();
        const excessGenerationData = { 
            rate // Changed price to rate
        };

        try {
            const response = await axios.post(StartUrl?.StartUrl + '/ssc/excessgenerations/create', excessGenerationData);
            console.log(response.data);
            if (response?.data?.status === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Insert Success!",
                    text: `${response?.data?.message}`,
                });
                navigate("/excessgenerations");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `${response?.data?.message}`,
                });
            }
        } catch (error) {
            console.error("There was an error creating the excess generation:", error);
            if (error.response) {
                console.error("Error response data:", error.response.data);
                console.error("Error response status:", error.response.status);
                console.error("Error response headers:", error.response.headers);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Server responded with status ${error.response.status}: ${error.response.data?.message || error.response.data}`,
                });
            } else if (error.request) {
                console.error("Error request data:", error.request);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: "No response received from the server.",
                });
            } else {
                console.error("Error message:", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Error in setting up the request: ${error.message}`,
                });
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg h-auto bg-white p-8 shadow-md rounded-lg">
                <h2 className="text-2xl font-bold mb-4">Add Excess Generation</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
                            Rate
                        </label>
                        <input
                            type="number"
                            id="rate"
                            value={rate} // Changed price to rate
                            onChange={(e) => setRate(e.target.value)} // Changed setPrice to setRate
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter rate"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddExcessGeneration;
