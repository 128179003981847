import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';

const ExcessGenerations = () => {
  const navigate = useNavigate();
  const [allExcessGenerations, setAllExcessGenerations] = useState([]);

  const getAllExcessGenerations = async () => {
    try {
      const response = await axios.get(StartUrl?.StartUrl + "/ssc/excessgenerations/all");
      setAllExcessGenerations(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllExcessGenerations();
  }, []);

  const handleEdit = async (id) => {
    navigate(`/edit-excess-generations/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.delete(StartUrl?.StartUrl + "/ssc/excessgenerations/delete/" + id);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          getAllExcessGenerations();
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black">
          Excess Generation Rate
        </h4>
        <button
          className="bg-black text-white p-2 rounded-full"
          style={{ borderRadius: '5px' }}
          onClick={() => { navigate("/add-excess-generations") }}
        >
          Add Excess Generation
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Rate {/* Changed Price to Rate */}
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {allExcessGenerations.map((excessGeneration) => (
                  <tr key={excessGeneration._id}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {excessGeneration.rate} {/* Changed price to rate */}
                    </td>
                    <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                      <button onClick={() => handleEdit(excessGeneration._id)} className="text-green-500 hover:text-green-700">
                        Edit
                      </button>
                    </td>
                    <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                      <button onClick={() => handleDelete(excessGeneration._id)} className="text-red-500 hover:text-red-700">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcessGenerations;
