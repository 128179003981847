import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

function Editunit() {
  const navigate = useNavigate();
  const { id } = useParams();  
  const [unit_name, setUnitName] = useState("");
  const [lowerLevel, setLowerLevel] = useState("");
  const [upperLevel, setUpperLevel] = useState("");
  const [rate, setRate] = useState("");
  const [fixedCharge, setFixedCharge] = useState("");
  const [blocksInUnit, setBlocksInUnit] = useState("");

  const getUnitById = async () => {
    try {
      const response = await axios.get(StartUrl?.StartUrl + "/ssc/units/" + id);
      console.log("Unit data received:", response?.data?.data?.data);
      setUnitName(response?.data?.data?.data?.unit_name);
      setLowerLevel(response?.data?.data?.data?.lowerLevel);
      setUpperLevel(response?.data?.data?.data?.upperLevel);
      setRate(response?.data?.data?.data?.rate);
      setFixedCharge(response?.data?.data?.data?.fixedCharge);
      setBlocksInUnit(response?.data?.data?.data?.blocksInUnit);
    } catch (error) {
      console.log("Error fetching unit data:", error);
    }
  };

  useEffect(() => {
    getUnitById();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const unitData = { unit_name, lowerLevel, upperLevel, rate, fixedCharge, blocksInUnit };
    console.log("Updating unit with data:", unitData);
    
    try {
      const response = await axios.put(StartUrl?.StartUrl + '/ssc/units/update/' + id, unitData);
      console.log("Update response:", response.data);
      if (response?.data?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Update Success!",
          text: `${response?.data?.message}`,
        });
        navigate("/units");
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed..!",
          text: `${response?.data?.message}`,
        });
      }
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl h-auto mx-auto bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Update Unit</h2>
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unit_name">
              Unit Name
            </label>
            <input
              type="text"
              id="unit_name"
              value={unit_name}
              onChange={(e) => setUnitName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter unit name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lowerLevel">
              Lower Level
            </label>
            <input
              type="text"
              id="lowerLevel"
              value={lowerLevel}
              onChange={(e) => setLowerLevel(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter lower level"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="upperLevel">
              Upper Level
            </label>
            <input
              type="text"
              id="upperLevel"
              value={upperLevel}
              onChange={(e) => setUpperLevel(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter upper level"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
              Rate
            </label>
            <input
              type="text"
              id="rate"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter rate"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fixedCharge">
              Fixed Charge
            </label>
            <input
              type="text"
              id="fixedCharge"
              value={fixedCharge}
              onChange={(e) => setFixedCharge(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter fixed charge"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="blocksInUnit">
              Blocks In Unit
            </label>
            <input
              type="text"
              id="blocksInUnit"
              value={blocksInUnit}
              onChange={(e) => setBlocksInUnit(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter blocks in unit"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}

export default Editunit;
