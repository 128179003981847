import React, { useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function AddBank() {
    const navigate = useNavigate();
    const [bank, setBank] = useState("");
    const [rates, setRates] = useState([{ term: "", interest: "", kW: "", loanAmount: "" }]);

    const handleRateChange = (index, event) => {
        const newRates = rates.map((rate, i) => {
            if (i === index) {
                return { ...rate, [event.target.name]: event.target.value };
            }
            return rate;
        });
        setRates(newRates);
    };

    const addRate = () => {
        setRates([...rates, { term: "", interest: "", kW: "", loanAmount: "" }]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bankData = { bank, rates };

        try {
            const response = await axios.post(`${StartUrl.StartUrl}/ssc/banks/createBank`, bankData);
            console.log(response.data);
            if (response?.data?.status === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Insert Success!",
                    text: `${response?.data?.message}`,
                });
                navigate("/banks");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `${response?.data?.message}`,
                });
            }
        } catch (error) {
            console.error("There was an error creating the bank:", error);
            if (error.response) {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Server responded with status ${error.response.status}: ${error.response.data?.message || error.response.data}`,
                });
            } else if (error.request) {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: "No response received from the server.",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Error in setting up the request: ${error.message}`,
                });
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg h-auto bg-white p-8 shadow-md rounded-lg">
                <h2 className="text-2xl font-bold mb-4">Add Bank</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bank">
                            Bank
                        </label>
                        <input
                            type="text"
                            id="bank"
                            value={bank}
                            onChange={(e) => setBank(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter bank name"
                        />
                    </div>
                    {rates.map((rate, index) => (
                        <div key={index} className="mb-4">
                            <h3 className="text-lg font-bold mb-2">Rate {index + 1}</h3>
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`term-${index}`}>
                                Term
                            </label>
                            <input
                                type="text"
                                id={`term-${index}`}
                                name="term"
                                value={rate.term}
                                onChange={(e) => handleRateChange(index, e)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter term"
                            />
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`interest-${index}`}>
                                Interest
                            </label>
                            <input
                                type="text"
                                id={`interest-${index}`}
                                name="interest"
                                value={rate.interest}
                                onChange={(e) => handleRateChange(index, e)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter interest rate"
                            />
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`kW-${index}`}>
                                kW
                            </label>
                            <input
                                type="text"
                                id={`kW-${index}`}
                                name="kW"
                                value={rate.kW}
                                onChange={(e) => handleRateChange(index, e)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter kW"
                            />
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`loanAmount-${index}`}>
                                Loan Amount
                            </label>
                            <input
                                type="text"
                                id={`loanAmount-${index}`}
                                name="loanAmount"
                                value={rate.loanAmount}
                                onChange={(e) => handleRateChange(index, e)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Enter loan amount"
                            />
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addRate}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
                    >
                        Add Another Rate
                    </button>
                    <div className="mb-4"></div> {/* Add this div for extra space */}
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddBank;
