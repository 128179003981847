import { useContext } from "react";
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import AuthContext from "./components/context/Auth.context";
import Login from "./components/auth/Login";
import Units from "./components/Units/Units";
import Addunit from "./components/Units/Addunit";
import Editunit from "./components/Units/Editunits";
import CalUnits from "./components/CalUnits/CalUnits";
import Banks from "./components/Banks/Banks";
import AddBank from "./components/Banks/Addbanks";
import EditBank from "./components/Banks/Editbanks";
import SolarPackages from "./components/SolarPackages/SolarPackages";
import AddSolarPackage from "./components/SolarPackages/AddSolarPackage";
import EditSolarPackage from "./components/SolarPackages/EditSolarPackage";
import ExcessGenerations from "./components/ExcessGenerations/ExcessGenerations";
import AddExcessGeneration from "./components/ExcessGenerations/AddExcessGeneration";
import EditExcessGeneration from "./components/ExcessGenerations/EditExcessGeneration";

export default function Router() {
  const { Token, userRole, userLogged } = useContext(AuthContext);

  console.log(useContext(AuthContext));

  return (
    <div>
      <Routers>
        <Routes>
          {!userLogged && (<Route index path="/" element={<CalUnits />} />)}
          {userLogged ? (
            <>
              <Route index path="/" element={<Home />} />
              <Route path="/units" element={<Units />} />
              <Route path="/add-units" element={<Addunit />} />
              <Route path="/edit-units/:id" element={<Editunit />} />
              <Route path="/cal-units" element={<CalUnits />} />
              <Route path="/banks" element={<Banks />} />
              <Route path="/add-banks" element={<AddBank />} />
              <Route path="/edit-banks/:id" element={<EditBank />} />
              <Route path="/solarpackages" element={<SolarPackages />} />
              <Route path="/add-solarpackage" element={<AddSolarPackage />} />
              <Route path="/edit-solarpackage/:id" element={<EditSolarPackage />} />
              <Route path="/excess-generations" element={<ExcessGenerations />} />
              <Route path="/add-excess-generations" element={<AddExcessGeneration />} />
              <Route path="/edit-excess-generations/:id" element={<EditExcessGeneration />} />
            </>
          ) : (
            <>
              <Route path="/solar-dashboard-login" element={<Login />} />
            </>
          )}
        </Routes>
      </Routers> 
    </div>
  );
}