import React from 'react';

function Home() {

  const logout = ()=>{
    localStorage.removeItem("token");
    localStorage.removeItem("userRole");
    localStorage.removeItem("user");
    localStorage.removeItem("userID");
    localStorage.removeItem("_id");
    window.location.reload();
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div aria-label="card" className="p-8 rounded-3xl bg-white max-w-sm w-full">
        <div aria-label="header" className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 shrink-0"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
          </svg>
          <div className="space-y-0.5 flex-1">
            <h3 className="font-medium text-lg tracking-tight text-gray-900 leading-tight">
              Hayleys Solar 
            </h3>
            <p className="text-sm font-normal text-gray-400 leading-none">
              Loan Calculator Admin
            </p>
          </div>
          <button
            onClick={logout}
            className="inline-flex items-center shrink-0 justify-center w-8 h-8 rounded-full text-white bg-white-900 focus:outline-none"
          >
            <img src="https://icons.veryicon.com/png/o/internet--web/website-icons/logout-8.png"  alt='asdasd'     />
          </button>
        </div>
        <div aria-label="content" className="mt-9 grid gap-2.5">
          <a href="/units">
            <div className="flex items-center space-x-4 p-3.5 rounded-full bg-gray-100">
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 16a3 3 0 0 1 -3 3" />
                  <path d="M16 16a3 3 0 0 0 3 3" />
                  <path d="M12 16v4" />
                  <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  <path d="M7 13v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3" />
                </svg>
              </span>
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">Unit Price</h3>
                <div className="divide-x divide-gray-200 mt-auto">
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    Manage Unit Prices
                  </span>
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    
                  </span>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </div>
          </a>
          {/* Repeat similar structure for other items */}
          <a href="/banks">
            <div className="flex items-center space-x-4 p-3.5 rounded-full bg-gray-100">
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 16a3 3 0 0 1 -3 3" />
                  <path d="M16 16a3 3 0 0 0 3 3" />
                  <path d="M12 16v4" />
                  <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  <path d="M7 13v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3" />
                </svg>
              </span>
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">Banks</h3>
                <div className="divide-x divide-gray-200 mt-auto">
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    Manage Banks and Interest %
                  </span>
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    
                  </span>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </div>
          </a>
          {/* Repeat similar structure for other items */}
          <a href="solarpackages">
            <div className="flex items-center space-x-4 p-3.5 rounded-full bg-gray-100">
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 16a3 3 0 0 1 -3 3" />
                  <path d="M16 16a3 3 0 0 0 3 3" />
                  <path d="M12 16v4" />
                  <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  <path d="M7 13v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3" />
                </svg>
              </span>
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">Solar Packages</h3>
                <div className="divide-x divide-gray-200 mt-auto">
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    Manage Solar Packages
                  </span>
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    
                  </span>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </div>
          </a>
          {/* Repeat similar structure for other items */}

          <a href="/excess-generations">
            <div className="flex items-center space-x-4 p-3.5 rounded-full bg-gray-100">
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 16a3 3 0 0 1 -3 3" />
                  <path d="M16 16a3 3 0 0 0 3 3" />
                  <path d="M12 16v4" />
                  <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  <path d="M7 13v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3" />
                </svg>
              </span>
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">Excess Generation</h3>
                <div className="divide-x divide-gray-200 mt-auto">
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    Excess Generation Rate 
                  </span>
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    
                  </span>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </div>
          </a>
          
          {/* Repeat similar structure for other items */}
          <a href="/cal-units">
            <div className="flex items-center space-x-4 p-3.5 rounded-full bg-gray-100">
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 16a3 3 0 0 1 -3 3" />
                  <path d="M16 16a3 3 0 0 0 3 3" />
                  <path d="M12 16v4" />
                  <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                  <path d="M7 13v-3a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v3" />
                </svg>
              </span>
              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">Calculator</h3>
                <div className="divide-x divide-gray-200 mt-auto">
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    Calculator 
                  </span>
                  <span className="inline-block px-3 text-xs leading-none text-gray-400 font-normal first:pl-0">
                    
                  </span>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
            </div>
          </a>

        </div>
      </div>
    </div>
    
  );
}

export default Home;
