import React, { useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Addunit() {
    const navigate = useNavigate();
    const [unit_name, setUnitName] = useState("");
    const [lowerLevel, setLowerLevel] = useState("");
    const [upperLevel, setUpperLevel] = useState("");
    const [rate, setRate] = useState("");
    const [fixedCharge, setFixedCharge] = useState("");
    const [blocksInUnit, setBlocksInUnit] = useState(""); // New state for blocksInUnit

    const handleSubmit = async (event) => {
        event.preventDefault();
        const unitData = { 
            unit_name,
            lowerLevel,
            upperLevel,
            rate,
            fixedCharge,
            blocksInUnit // Include blocksInUnit in the request data
        };

        try {
            const response = await axios.post(StartUrl?.StartUrl + '/ssc/units/create', unitData);
            console.log(response.data);
            if (response?.data?.status === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Insert Success!",
                    text: `${response?.data?.message}`,
                });
                navigate("/units");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `${response?.data?.message}`,
                });
            }
        } catch (error) {
            console.error("There was an error creating the unit:", error);
            if (error.response) {
                console.error("Error response data:", error.response.data);
                console.error("Error response status:", error.response.status);
                console.error("Error response headers:", error.response.headers);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Server responded with status ${error.response.status}: ${error.response.data?.message || error.response.data}`,
                });
            } else if (error.request) {
                console.error("Error request data:", error.request);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: "No response received from the server.",
                });
            } else {
                console.error("Error message:", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Error in setting up the request: ${error.message}`,
                });
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg h-auto bg-white p-8 shadow-md rounded-lg">
                <h2 className="text-2xl font-bold mb-4">Add Unit</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unit_name">
                            Unit Name
                        </label>
                        <input
                            type="text"
                            id="unit_name"
                            value={unit_name}
                            onChange={(e) => setUnitName(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter unit name"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lowerLevel">
                            Lower Level
                        </label>
                        <input
                            type="text"
                            id="lowerLevel"
                            value={lowerLevel}
                            onChange={(e) => setLowerLevel(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter lower level"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="upperLevel">
                            Upper Level
                        </label>
                        <input
                            type="text"
                            id="upperLevel"
                            value={upperLevel}
                            onChange={(e) => setUpperLevel(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter upper level"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate">
                            Rate
                        </label>
                        <input
                            type="text"
                            id="rate"
                            value={rate}
                            onChange={(e) => setRate(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter rate"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fixedCharge">
                            Fixed Charge
                        </label>
                        <input
                            type="text"
                            id="fixedCharge"
                            value={fixedCharge}
                            onChange={(e) => setFixedCharge(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter fixed charge"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="blocksInUnit">
                            Blocks In Unit
                        </label>
                        <input
                            type="text"
                            id="blocksInUnit"
                            value={blocksInUnit}
                            onChange={(e) => setBlocksInUnit(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter blocks in unit"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Addunit;
