import axios from "axios";
import React, { useEffect, useState } from "react";
import StartUrl from "../../configs/Url.json";
import Modal from "react-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faMoneyBill,
  faTree,
  faSolarPanel,
  faPhone,
  faInfoCircle,
  faPhoneAlt,
  faCreditCard,
  faTachometerAlt,
  faChartPie,
  faMoneyBillWave, // Importing the money note icon
} from "@fortawesome/free-solid-svg-icons";
import CommercialBankImage from "../../assets/images/commercial_bank.png";
import BankOfCeylonImage from "../../assets/images/bank_of_ceylon.png";
import PeoplesBankImage from "../../assets/images/peoples_bank.png";
import SampathBankImage from "../../assets/images/sampath_bank.png";
import SeylanBankImage from "../../assets/images/seylan_bank.png";
import CDBImage from "../../assets/images/cdb.png";
import HNBImage from "../../assets/images/hnb.png";
import PeoplesLeasingBankImage from "../../assets/images/peoples_leasing.png";
import LOLCFinanceImage from "../../assets/images/lolc_finance.png";
import NDBImage from "../../assets/images/ndb_bank.png";
import DFCCBankImage from "../../assets/images/dfcc.png";
import UnionBankImage from "../../assets/images/union_bank.png";
import HSBCBankImage from "../../assets/images/hsbc.png";
import SolarBannerImage from "../../assets/images/solar-banner.jpg";

const CalUnits = () => {
  const [monthlyBill, setMonthlyBill] = useState(0.0);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [monthlyUnits, setMonthlyUnits] = useState(0.0);
  const [allUnits, setAllUnits] = useState([]);
  const [solarPackages, setSolarPackages] = useState([]);
  const [systemSelectedPackage, setSystemSelectedPackage] = useState({});
  const [packageAvailable, setPackageAvailable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [savings, setSavings] = useState(null);
  const [error, setError] = useState(""); // State for validation error

  const getAllUnits = async () => {
    try {
      const response = await axios.get(StartUrl.StartUrl + "/ssc/units/all");
      setAllUnits(response.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSolarPackages = async () => {
    try {
      const response = await axios.get(
        StartUrl.StartUrl + "/ssc/solarpackages/getAllSolarPackages"
      );
      setSolarPackages(response.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBanks = async () => {
    try {
      const response = await axios.get(
        StartUrl.StartUrl + "/ssc/banks/getAllBanks"
      );
      setBanks(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUnits();
    getAllSolarPackages();
    getAllBanks();
  }, []);

  const handleBankChange = (event) => {
    const selectedBankId = event.target.value;
    setSelectedBank(selectedBankId);

    const selectedBank = banks.find((bank) => bank._id === selectedBankId);
    const bankRates = selectedBank ? selectedBank.rates : [];
    setTerms(bankRates);

    if (bankRates.length === 1) {
      setSelectedTerm(bankRates[0].term);
    } else {
      setSelectedTerm(""); // Reset selected term if there are multiple rates
    }
  };

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
  };

  const handleBillChange = (e) => {
    const value = e.target.value;
    setMonthlyBill(value);
    setError(""); // Clear error when bill amount changes
  };

  const billCalculator = (event) => {
    event.preventDefault();
    if (monthlyBill < 4860) {
      setError("The electricity bill amount should not be less than LKR 4860");
      return;
    }
    setIsSubmitted(true);
    let Rate0_60;
    let Rate60_90;
    let Rate90_120;
    let Rate120_180;
    let Rate180;
    allUnits.forEach((unit) => {
      if (unit.lowerLevel === 0) {
        Rate0_60 = parseFloat(unit.rate);
      } else if (unit.lowerLevel === 60) {
        Rate60_90 = parseFloat(unit.rate);
      } else if (unit.lowerLevel === 90) {
        Rate90_120 = parseFloat(unit.rate);
      } else if (unit.lowerLevel === 120) {
        Rate120_180 = parseFloat(unit.rate);
      } else if (unit.lowerLevel === 180) {
        Rate180 = parseFloat(unit.rate);
      }
    });
    let fixChargeTot = 0.0;
    if (monthlyBill <= 900) {
      fixChargeTot = 0.0;
    } else if (monthlyBill <= 1840) {
      fixChargeTot = 400.0;
    } else if (monthlyBill <= 3340) {
      fixChargeTot = 1000.0;
    } else if (monthlyBill <= 6360) {
      fixChargeTot = 1500.0;
    } else {
      fixChargeTot = 2000.0;
    }
    let CalculatedUnits = 0.0;
    let MonthlyBillMinusFixCharge = monthlyBill - fixChargeTot;
    let level = 0;
    if (MonthlyBillMinusFixCharge > 4860) {
      CalculatedUnits = (MonthlyBillMinusFixCharge - 4860) / Rate180;
      level = 180;
    } else if (MonthlyBillMinusFixCharge > 2340) {
      CalculatedUnits = (MonthlyBillMinusFixCharge - 2340) / Rate120_180;
      level = 120;
    } else if (MonthlyBillMinusFixCharge > 1440) {
      CalculatedUnits = (MonthlyBillMinusFixCharge - 1440) / Rate90_120;
      level = 90;
    } else if (MonthlyBillMinusFixCharge > 900) {
      CalculatedUnits = (MonthlyBillMinusFixCharge - 900) / Rate60_90;
      level = 60;
    } else {
      CalculatedUnits = (MonthlyBillMinusFixCharge) / Rate0_60;
      level = 0;
    }
    let totalUnits1bTo4b = 0;
    allUnits.forEach((unit) => {
      if (level !== parseInt(unit.lowerLevel)) {
        totalUnits1bTo4b += parseFloat(unit.blocksInUnit);
      }
    });
    let finalUnits = 0.0;
    finalUnits = CalculatedUnits + parseFloat(totalUnits1bTo4b);
    finalUnits = finalUnits.toFixed(0);
    setMonthlyUnits(finalUnits);
    selectSolarPackageForUser(finalUnits);
  };

  const selectSolarPackageForUser = (monthlyUnits) => {
    let selectedSolarPackage = {};
    let packageFound = false;
    let packages = solarPackages.filter(pkg => pkg.inverter_type === selectedPackage);
    
    // Sort packages by average monthly generation
    packages.sort((a, b) => a.avg_monthly_generation - b.avg_monthly_generation);

    for (let i = 0; i < packages.length; i++) {
      if (monthlyUnits <= packages[i].avg_monthly_generation) {
        selectedSolarPackage = packages[i];
        packageFound = true;
        break;
      }
    }

    if (!packageFound) {
      selectedSolarPackage = packages[packages.length - 1];
    }

    setPackageAvailable(packageFound);
    setSystemSelectedPackage(selectedSolarPackage);

    if (packageFound && selectedTerm && selectedBank) {
      calculateMonthlyPayment(
        selectedSolarPackage.selling_price,
        selectedTerm,
        selectedBank,
        selectedSolarPackage,
        monthlyUnits
      );
    }

    if (!packageFound) {
      setIsModalOpen(true);
    }
  };

  const getExcessgenerationsValue = async()=>{
    try {
      const response = await axios.get(
        StartUrl.StartUrl + "/ssc/excessgenerations/all"
      );
      console.log(response)
      return parseFloat(response?.data?.data?.data[0]?.rate);
    } catch (error) {
      console.error(error);
    }
  }

  const calculateMonthlyPayment = async (loanAmount, term, bankId,selectedSolarPackage,monthlyUnitsInside) => {
    const ExcessGenValue = await getExcessgenerationsValue()
    const selectedBank = banks.find((bank) => bank._id === bankId);
    const selectedRate = selectedBank.rates.find((rate) => rate.term === term);

    const annualInterestRate = parseFloat(selectedRate.interest) / 100;
    const loanTerm = parseInt(term);

    const monthlyInterestRate = annualInterestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment =
      (loanAmount * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    setMonthlyPayment(monthlyPayment.toFixed(2));
    const monthlyMinusUnitsValue = (parseFloat(selectedSolarPackage?.avg_monthly_generation) - parseFloat(monthlyUnitsInside)) * ExcessGenValue;
    console.table(ExcessGenValue,monthlyMinusUnitsValue,selectedSolarPackage?.avg_monthly_generation,monthlyUnitsInside,(parseFloat((monthlyBill - monthlyPayment).toFixed(2)) + monthlyMinusUnitsValue));

    setSavings(parseFloat((monthlyBill - monthlyPayment).toFixed(2)) + monthlyMinusUnitsValue);

  };

  const closeModal = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/solar-banner.jpg)", // Ensure this path is correct
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "25px", fontWeight: "bold", color : "#86bb46", marginBottom: "0px" }}>Hayleys Solar</h1>
        <h2 style={{ fontSize: "38px", fontWeight: "bold", color : "#FC631B", marginBottom: "16px" }}>Savings Calculator</h2>
        <button
          style={{
            backgroundColor: "transparent",
            border: "2px solid white",
            color: "white",
            padding: "10px 20px",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => window.location.href = "https://www.hayleyssolar.com"}
        >
          BACK TO HAYLEYS SOLAR
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f7f7f7",
          padding: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "800px",
            backgroundColor: "white",
            padding: "32px",
            boxShadow: "0 0 15px rgba(0,0,0,0.1)",
            borderRadius: "8px",
          }}
        >
          <h2
            style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}
          >
            Hayleys Solar Savings Calculator
          </h2>
          <form onSubmit={billCalculator}>
            <div style={{ marginBottom: "16px" }}>
              <label
                style={{ display: "block", color: "#333", marginBottom: "8px" }}
                htmlFor="monthlyBill"
              >
                What's your average monthly electricity bill?
              </label>
              <input
                type="number"
                id="monthlyBill"
                value={monthlyBill}
                onChange={handleBillChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
                placeholder="Enter monthly bill"
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
              <small>Min: LKR 4860</small>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <div style={{ flex: "1", marginRight: "8px" }}>
                <label
                  style={{ display: "block", color: "#333", marginBottom: "8px" }}
                  htmlFor="package"
                >
                  Phase
                </label>
                <div style={{ position: "relative" }}>
                  <select
                    id="package"
                    value={selectedPackage}
                    onChange={(e) => setSelectedPackage(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px 32px 8px 8px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="">Select Phase</option>
                    <option value="Single Phase">Single Phase</option>
                    <option value="Three Phase">Three Phase</option>
                  </select>
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      color: "#888",
                      fontSize: "16px",
                    }}
                  >
                    ▼
                  </span>
                </div>
              </div>
              <div style={{ flex: "1", marginLeft: "8px" }}>
                <label
                  style={{ display: "block", color: "#333", marginBottom: "8px" }}
                  htmlFor="bank"
                >
                  Bank
                </label>
                <div style={{ position: "relative" }}>
                  <select
                    id="bank"
                    value={selectedBank}
                    onChange={handleBankChange}
                    style={{
                      width: "100%",
                      padding: "8px 32px 8px 8px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                      backgroundColor: "#fff",
                    }}
                  >
                    <option value="">Select Bank</option>
                    {banks.map((bank) => (
                      <option key={bank._id} value={bank._id}>
                        {bank.bank}
                      </option>
                    ))}
                  </select>
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      color: "#888",
                      fontSize: "16px",
                    }}
                  >
                    ▼
                  </span>
                </div>
              </div>
            </div>
            {terms.length > 0 && (
              <div style={{ marginBottom: "16px" }}>
                <label
                  style={{ display: "block", color: "#333", marginBottom: "8px" }}
                  htmlFor="term"
                >
                  Term
                </label>
                <div style={{ position: "relative" }}>
                  {terms.length === 1 ? (
                    <div
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      }}
                    >
                      {terms[0].term} Years - {terms[0].interest}% Interest
                    </div>
                  ) : (
                    <select
                      id="term"
                      value={selectedTerm}
                      onChange={handleTermChange}
                      style={{
                        width: "100%",
                        padding: "8px 32px 8px 8px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        appearance: "none",
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        backgroundColor: "#fff",
                      }}
                    >
                      <option value="">Select Term</option>
                      {terms.map((term, index) => (
                        <option key={index} value={term.term}>
                          {term.term} Years - {term.interest}% Interest
                        </option>
                      ))}
                    </select>
                  )}
                  {terms.length > 1 && (
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "8px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#888",
                        fontSize: "16px",
                      }}
                    >
                      ▼
                    </span>
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <button
                type="submit"
                style={{
                  backgroundColor: "#FC631B",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginTop : "20px",
                }}
              >
                Calculate
              </button>
            </div>
          </form>
          {isSubmitted && (
            <div style={{ marginTop: "16px" }}>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Let's look at your total home energy savings
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                  flexWrap: "wrap",
                  gap: "8px", // Added to reduce gap between boxes
                }}
              >
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    Recommended solar installation size
                  </h4>
                  <p>Solar installations are sized in kilowatts</p>
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    {systemSelectedPackage?.inverter_capacity?.toLocaleString()} kW
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "8px",
                  flexWrap: "wrap",
                  gap: "8px", // Added to reduce gap between boxes
                }}
                className="flex-col gap-5 mb:flex-row xl:flex-row"
              >
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSolarPanel}
                    style={{
                      fontSize: "24px",
                      color: "#28a745",
                      marginBottom: "8px",
                    }}
                  />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    Avg Monthly Generation
                  </h4>
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    {systemSelectedPackage?.avg_monthly_generation?.toLocaleString()}{" "}
                    kWh
                  </p>
                </div>
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard} // Changed to credit card icon
                    style={{
                      fontSize: "24px",
                      color: "#28a745",
                      marginBottom: "8px",
                    }}
                  />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    Investment
                  </h4>
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    LKR{" "}
                    {parseFloat(
                      systemSelectedPackage?.selling_price
                    )?.toLocaleString()}
                  </p>
                </div>
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBolt}
                    style={{
                      fontSize: "24px",
                      color: "#28a745",
                      marginBottom: "8px",
                    }}
                  />
                  <h4
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    No of Units Used
                  </h4>
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    {monthlyUnits?.toLocaleString()} Units
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginBottom: "8px", gap: "8px" }} // Added to reduce gap between boxes
              className="flex-col gap-5 mb:flex-row xl:flex-row">
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTachometerAlt} // Changed to electricity meter icon
                    style={{
                      fontSize: "24px",
                      color: "#28a745",
                      marginBottom: "8px",
                    }}
                  />
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    LKR {parseFloat(monthlyBill)?.toLocaleString()}
                  </p>
                  <p>Electricity Bill</p>
                </div>
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "#f8f9fa",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faChartPie} // Changed to pie chart icon
                    style={{
                      fontSize: "24px",
                      color: "#28a745",
                      marginBottom: "8px",
                    }}
                  />
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    LKR {parseFloat(monthlyPayment)?.toLocaleString()}
                  </p>
                  <p>Solar Monthly Installment</p>
                </div>
                <div
                  style={{
                    flex: "1",
                    backgroundColor: "rgb(252, 99, 27)",
                    padding: "12px",
                    borderRadius: "8px",
                    margin: "4px",
                    height: "150px",
                    color: "white",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBillWave} // Changed to money note icon
                    style={{
                      fontSize: "24px",
                      color: "white",
                      marginBottom: "8px",
                    }}
                  />
                  <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    LKR {parseFloat(savings)?.toLocaleString()}
                  </p>
                  <p>Monthly Savings</p>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#eaf5fc",
                  borderRadius: "4px",
                  padding: "12px",
                  marginTop: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ fontSize: "24px", color: "#1e90ff", marginRight: "12px" }}
                />
                <p style={{ color: "#333", fontSize: "16px", margin: "0" }}>
                Your system price and monthly savings can differ based on your required no. of solar panels or panel capacity
                </p>
              </div>
            </div>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Package Not Available"
            style={{
              overlay: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                position: "relative",
                width: "100%",
                maxWidth: "500px",
                background: "white",
                borderRadius: "8px",
                padding: "24px",
                boxShadow: "0 0 15px rgba(0,0,0,0.1)",
              },
            }}
          >
            <div>
              <button
                onClick={closeModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              <h2 style={{ fontSize: "24px", marginBottom: "16px" }}>
                Package Not Available
              </h2>
              <p style={{ marginBottom: "16px" }}>
                Package Not Available for Selected Phase... Change Phase and try again.
              </p>
              <button
                onClick={closeModal}
                style={{
                  width: "100%",
                  backgroundColor: "#FC631B",
                  color: "white",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#86bb46",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "32px",
          color: "white",
          flexWrap: "wrap", // Added to ensure responsiveness
        }}
      >
        <div style={{ flex: "1 1 300px", padding: "0 20px" }}> {/* Adjusted flex for responsiveness */}
          <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "8px" }}>
            Book a call with our own bank loan consultant!
          </h2>
          <p>
            Feel free to get in touch with us for any billing-related enquiries or to give us feedback.
          </p>
        </div>
        <a 
          href="https://www.hayleyssolar.com/contact-us/" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{
            backgroundColor: "transparent",
            border: "2px solid white",
            color: "white",
            padding: "10px 20px",
            borderRadius: "4px",
            textDecoration: "none",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            margin: "20px", // Added margin for spacing in responsive design
            flex: "0 0 200px", // Adjusted flex for responsiveness
          }}
        >
          <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: "8px" }} />
          GET IN TOUCH
        </a>
      </div>
      <div style={{ padding: "32px 0", textAlign: "center", backgroundColor: "#fff" }}>
        <h2 style={{ fontSize: "38px", fontWeight: "bold", color: "#FC631B", marginBottom: "16px" }}>
          Our Banking Partners
        </h2>
        <Slider {...sliderSettings}>
          <div>
            <img src={CommercialBankImage} alt="Commercial Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={BankOfCeylonImage} alt="Bank of Ceylon" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={PeoplesBankImage} alt="People's Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={SampathBankImage} alt="Sampath Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={SeylanBankImage} alt="Seylan Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={CDBImage} alt="CDB Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={HNBImage} alt="HNB Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={PeoplesLeasingBankImage} alt="People's Leasing" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={LOLCFinanceImage} alt="LOLC Finance" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={NDBImage} alt="NDB Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={DFCCBankImage} alt="DFCC Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={UnionBankImage} alt="Union Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
          <div>
            <img src={HSBCBankImage} alt="HSBC Bank" style={{ width: "80%", padding: "0 16px" }} />
          </div>
        </Slider>
      </div>
      <a 
        href="https://www.hayleyssolar.com/contact-us/" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#FC631B",
          color: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <FontAwesomeIcon icon={faPhone} style={{ fontSize: "24px" }} />
      </a>
    </div>
  );
};

export default CalUnits;
