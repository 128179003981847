import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';

const Units = () => {
  const navigate = useNavigate();
  const [AllUnits, setAllUnits] = useState([]);

  const getAllAllUnits = async () => {
    try {
      const response = await axios.get(StartUrl?.StartUrl + "/ssc/units/all");
      setAllUnits(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAllUnits();
  }, []);

  const handleEdit = async (id) => {
    navigate(`/edit-units/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.delete(StartUrl?.StartUrl + "/ssc/units/delete/" + id);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
          getAllAllUnits();
        }
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between py-6 px-4 md:px-6 xl:px-7.5">
        <h4 className="text-xl font-semibold text-black">
          CEB Units Price List
        </h4>
        <button
          className="bg-black text-white p-2 rounded-full"
          style={{ borderRadius: '5px' }}
          onClick={() => { navigate("/add-units") }}
        >
          Add Units
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Unit Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Lower Level
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Upper Level
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Rate
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Fixed Charge
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Blocks In Unit
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {AllUnits.map((unit) => (
                  <tr key={unit._id}>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.unit_name}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.lowerLevel}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.upperLevel}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.rate}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.fixedCharge}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                      {unit.blocksInUnit}
                    </td>
                    <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                      <button onClick={() => handleEdit(unit._id)} className="text-green-500 hover:text-green-700">
                        Edit
                      </button>
                    </td>
                    <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                      <button onClick={() => handleDelete(unit._id)} className="text-red-500 hover:text-red-700">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Units;
