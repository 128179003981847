import React, { useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function AddSolarPackage() {
    const navigate = useNavigate();
    const [inverterType, setInverterType] = useState("");
    const [inverterCapacity, setInverterCapacity] = useState("");
    const [avgMonthlyGeneration, setAvgMonthlyGeneration] = useState("");
    const [sellingPrice, setSellingPrice] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const solarPackageData = { 
            inverter_type: inverterType,
            inverter_capacity: inverterCapacity,
            avg_monthly_generation: avgMonthlyGeneration,
            selling_price: sellingPrice
        };

        try {
            const response = await axios.post(StartUrl?.StartUrl + '/ssc/solarpackages/createSolarPackage', solarPackageData);
            console.log(response.data);
            if (response?.data?.status === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Insert Success!",
                    text: `${response?.data?.message}`,
                });
                navigate("/solarpackages");
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `${response?.data?.message}`,
                });
            }
        } catch (error) {
            console.error("There was an error creating the solar package:", error);
            if (error.response) {
                console.error("Error response data:", error.response.data);
                console.error("Error response status:", error.response.status);
                console.error("Error response headers:", error.response.headers);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Server responded with status ${error.response.status}: ${error.response.data?.message || error.response.data}`,
                });
            } else if (error.request) {
                console.error("Error request data:", error.request);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: "No response received from the server.",
                });
            } else {
                console.error("Error message:", error.message);
                Swal.fire({
                    icon: "error",
                    title: "Insert Failed!",
                    text: `Error in setting up the request: ${error.message}`,
                });
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="w-full max-w-lg h-auto bg-white p-8 shadow-md rounded-lg">
                <h2 className="text-2xl font-bold mb-4">Add Solar Package</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inverterType">
                            Inverter Type
                        </label>
                        <input
                            type="text"
                            id="inverterType"
                            value={inverterType}
                            onChange={(e) => setInverterType(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter inverter type"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inverterCapacity">
                            Inverter Capacity
                        </label>
                        <input
                            type="text"
                            id="inverterCapacity"
                            value={inverterCapacity}
                            onChange={(e) => setInverterCapacity(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter inverter capacity"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="avgMonthlyGeneration">
                            Avg Monthly Generation
                        </label>
                        <input
                            type="text"
                            id="avgMonthlyGeneration"
                            value={avgMonthlyGeneration}
                            onChange={(e) => setAvgMonthlyGeneration(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter average monthly generation"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellingPrice">
                            Selling Price
                        </label>
                        <input
                            type="text"
                            id="sellingPrice"
                            value={sellingPrice}
                            onChange={(e) => setSellingPrice(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter selling price"
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddSolarPackage;
