import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

function EditBank() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bank, setBank] = useState("");
  const [rates, setRates] = useState([{ term: "", interest: "", kW: "", loanAmount: "" }]);

  const getBankById = async () => {
    try {
      const response = await axios.get(`${StartUrl.StartUrl}/ssc/banks/getOneBank/${id}`);
      console.log("Bank data received:", response?.data?.data);
      setBank(response?.data?.data?.bank);
      setRates(response?.data?.data?.rates);
    } catch (error) {
      console.log("Error fetching bank data:", error);
    }
  };

  useEffect(() => {
    getBankById();
  }, []);

  const handleRateChange = (index, event) => {
    const newRates = rates.map((rate, i) => {
      if (i === index) {
        return { ...rate, [event.target.name]: event.target.value };
      }
      return rate;
    });
    setRates(newRates);
  };

  const addRate = () => {
    setRates([...rates, { term: "", interest: "", kW: "", loanAmount: "" }]);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const bankData = { bank, rates };
    console.log("Updating bank with data:", bankData);

    try {
      const response = await axios.put(`${StartUrl.StartUrl}/ssc/banks/updateBank/${id}`, bankData);
      console.log("Update response:", response.data);
      if (response?.data?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Update Success!",
          text: `${response?.data?.message}`,
        });
        navigate("/banks");
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed..!",
          text: `${response?.data?.message}`,
        });
      }
    } catch (error) {
      console.error("Error updating bank:", error);
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Update Failed!",
          text: `Server responded with status ${error.response.status}: ${error.response.data?.message || error.response.data}`,
        });
      } else if (error.request) {
        Swal.fire({
          icon: "error",
          title: "Update Failed!",
          text: "No response received from the server.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed!",
          text: `Error in setting up the request: ${error.message}`,
        });
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl h-auto mx-auto bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Update Bank</h2>
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bank">
              Bank
            </label>
            <input
              type="text"
              id="bank"
              value={bank}
              onChange={(e) => setBank(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter bank name"
            />
          </div>
          {rates.map((rate, index) => (
            <div key={index} className="mb-4">
              <h3 className="text-lg font-bold mb-2">Rate {index + 1}</h3>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`term-${index}`}>
                Term
              </label>
              <input
                type="text"
                id={`term-${index}`}
                name="term"
                value={rate.term}
                onChange={(e) => handleRateChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter term"
              />
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`interest-${index}`}>
                Interest
              </label>
              <input
                type="text"
                id={`interest-${index}`}
                name="interest"
                value={rate.interest}
                onChange={(e) => handleRateChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter interest rate"
              />
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`kW-${index}`}>
                kW
              </label>
              <input
                type="text"
                id={`kW-${index}`}
                name="kW"
                value={rate.kW}
                onChange={(e) => handleRateChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter kW"
              />
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`loanAmount-${index}`}>
                Loan Amount
              </label>
              <input
                type="text"
                id={`loanAmount-${index}`}
                name="loanAmount"
                value={rate.loanAmount}
                onChange={(e) => handleRateChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter loan amount"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addRate}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
          >
            Add Another Rate
          </button>
          <div className="mb-4"></div> {/* Add this div for extra space */}
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditBank;
