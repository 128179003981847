import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StartUrl from "../../configs/Url.json";
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

function EditExcessGeneration() {
  const navigate = useNavigate();
  const { id } = useParams();  
  const [rate, setRate] = useState(""); // Changed price to rate

  const getExcessGenerationById = async () => {
    try {
      const response = await axios.get(StartUrl?.StartUrl + "/ssc/excessgenerations/" + id);
      console.log("Excess generation data received:", response?.data?.data?.data);
      setRate(response?.data?.data?.data?.rate); // Set rate instead of price
    } catch (error) {
      console.log("Error fetching excess generation data:", error);
    }
  };

  useEffect(() => {
    getExcessGenerationById();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault();
    const excessGenerationData = { rate }; // Changed price to rate
    console.log("Updating excess generation with data:", excessGenerationData);
    
    try {
      const response = await axios.put(StartUrl?.StartUrl + '/ssc/excessgenerations/update/' + id, excessGenerationData);
      console.log("Update response:", response.data);
      if (response?.data?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Update Success!",
          text: `${response?.data?.message}`,
        });
        navigate("/excess-generations");
      } else {
        Swal.fire({
          icon: "error",
          title: "Update Failed..!",
          text: `${response?.data?.message}`,
        });
      }
    } catch (error) {
      console.error("Error updating excess generation:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-3xl h-auto mx-auto bg-white p-8 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Update Excess Generation</h2>
        <form onSubmit={handleUpdate}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="rate"> {/* Changed price to rate */}
              Rate
            </label>
            <input
              type="number"
              id="rate" // Changed price to rate
              value={rate} // Changed price to rate
              onChange={(e) => setRate(e.target.value)} // Changed setPrice to setRate
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter rate"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditExcessGeneration;
